/*  File name: contact.tsx
    Author name: Marcello Nunes Bernardes
    Website name: https://comp2112-final-marcellobernardes.onrender.com/
    File Description: Contact page, with a functioning form that displays the user input on the console as the submit button is pressed
*/

import React, { ChangeEvent, useEffect, useState } from "react";

// Creating consts to save and display user form input
function Contact(){
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [message, setMessage] = useState('');


    useEffect(() => {
        document.title = "Contact"
    },[]);
    
    //Functions that save the user input to the according const when the form field detects a change
    function onChangefirstName(e: ChangeEvent<HTMLInputElement>)
    {
        setfirstName(e.target.value);
    }

    function onChangelastName(e: ChangeEvent<HTMLInputElement>)
    {
        setlastName(e.target.value);
    }

    function onChangeContactNumber(e: ChangeEvent<HTMLInputElement>)
    {
        setContactNumber(e.target.value);
    }

    function onChangeEmailAddress(e: ChangeEvent<HTMLInputElement>)
    {
        setEmailAddress(e.target.value);
    }

    function onChangeMessage(e: ChangeEvent<HTMLTextAreaElement>)
    {
        setMessage(e.target.value);
    }

    // Function to display the user input on the console after it submits the form
    function onSubmitForm(e: any)
    {
        e.preventDefault();
        console.log("First name: " +firstName);
        console.log("Last name: " +lastName);
        console.log("Contact number: " +contactNumber);
        console.log("Email: " +emailAddress);
        console.log("Message: " +message);
    }

    return(

//CONTACT SECTION
<div className="container">
    <section id="contact" className="row">

        <div className="col-md-offset-2 col-md-8 col-xs-offset-1 col-xs-10">

            <div className="panel panel-primary">
                <div className="panel-heading">
                    <h3 className="panel-title">Contact Us</h3>
                </div>
                <div className="panel-body">
                    <form id="contactForm" className="form" onSubmit={onSubmitForm}>
                        <div className="form-group">
                            <label htmlFor="firstName">First Name</label>
                            <input type="text" className="form-control" id="firstName" name="firstName" placeholder="First Name" onChange={onChangefirstName} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastName">Last Name</label>
                            <input type="text" className="form-control" id="lastName" name="lastName" placeholder="Last Name" onChange={onChangelastName} required />
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="contactNumber">Contact Number</label>
                            <input type="tel" className="form-control" id="contactNumber" name="email" placeholder="Contact Number" onChange={onChangeContactNumber} required />
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="email">Email</label>
                            <input type="email" className="form-control" id="email" name="email" placeholder="Email" onChange={onChangeEmailAddress} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="comments">Your Message</label>
                            <textarea className="form-control" rows={5} id="yourMessage" name="comments" placeholder="Enter Your Comments Here" onChange={onChangeMessage} required></textarea>
                        </div>
                        <div className="text-right">
                            <button id="sendButton" type="submit" className="btn btn-primary">Send</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</div>

)
}

export default Contact;
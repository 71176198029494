/*  File name: About.tsx
    Author name: Marcello Nunes Bernardes
    Website name: https://comp2112-final-marcellobernardes.onrender.com/
    File Description: About the author page, with Picture and descriptive text
*/

import React from 'react';
import AuthorPhoto from '../../Assets/images/Author.jpg';

function About ()
{

    return(
        // Author skills text
        <div className="container" id="mainContent">
        <h1 id="MainTitle" className="col-lg-offset-1 col-lg-10">About the Author</h1>
        <article id="9Islands" className="row">
            <h2 className="col-md-offset-1 col-md-10 col-xs-offset-1 col-xs-10">Marcello Nunes Bernardes, Student id:200480634</h2>
            <hr />
            <ul>
                <li>I'm from Rio de Janeiro, Brazil.</li>
                <li>I’m interested in games, sports and travelling.</li>
                <li>I’m currently studying Interactive Media and Web Design at Georgian College, Barrie, ON.</li>
            </ul>
            <h3>Technical Skills</h3>
            <ul>
                <li>I am currenlty improving my HTML, CSS, PHP, Javascript, SQL skills and I have some basic knowledge with C#, Python and Java.</li>
                <li>I have a Bachelor's degree in Industrial Engineering at Pontifical Catholic University, in Rio de Janeiro.</li>
                <li>I am used to working using SCRUM methodology.</li>
                <li>Good with Office programs, specially Excel.</li>
            </ul>
            <h3>Soft Skills</h3>
            <ul>
                <li>Great at searching information online.</li>
                <li>Love working in teams with different backgrounds.</li>
                <li>Analitical thinking and organized.</li>
                <li>Not afraid of asking for and giving help to my teammates.</li>
            </ul>
        </article>
        {/* Author profile picture */}
        <img className="col-md-6 col-xs-offset-1  col-xs-10" src={AuthorPhoto} alt="Authors profile picture" />
        <hr />
        </div>
    )
}

export default About;
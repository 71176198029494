/*  File name: folegandros.tsx
    Author name: Marcello Nunes Bernardes
    Website name: https://comp2112-final-marcellobernardes.onrender.com/
    File Description: Component page
*/


import React from 'react';
import FolegandrosImg from '../../Assets/images/folegandros.jpg';

function Folegandros ()
{

    return(

    <div className="container">
        <article id="Folegandros" className="row">
            <h2 className="col-md-10 col-xs-offset-1 col-xs-10">Folegandros</h2>
            <img className="col-md-10 col-xs-offset-1 col-xs-10" src={FolegandrosImg} alt="Folegandros" />
            <p className="col-md-10 col-xs-offset-1 col-xs-10" id="Folegandros">
                Folegandros is almost a Greek cliché, full of beautiful whitewashed houses with bright blue doors lining cobblestoned streets on steep cliffs. Rugged and remote, without chain hotels or package tours — not even a bank or ATM — and accessible only by ferry, the volcanic island boasts solitude in spades, secluded beaches, and incredible sunsets.
            </p>
        </article>
        <hr />
    </div>
    );
}

export  default Folegandros;
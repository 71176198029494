/*  File name: alonissos.tsx
    Author name: Marcello Nunes Bernardes
    Website name: https://comp2112-final-marcellobernardes.onrender.com/
    File Description: Component page
*/


import React from 'react';
import AlonissosImg from '../../Assets/images/alonissos.jpg';

function Alonissos ()
{

    return(

<div className="container">
    <article id="Alonissos" className="row">
        <h2 className="col-md-10 col-xs-offset-1 col-xs-10">Alonissos</h2>
        <img className="col-md-10 col-xs-offset-1 col-xs-10" src={AlonissosImg} alt="Alonissos" />
        <p className="col-md-10 col-xs-offset-1 col-xs-10" id="Alonissos">
            Off the mainland, this island, whose surrounding waters are a designated marine park full of seals and dolphins, is a nature lover's dream. The spectacular spot's crystal clear waters and beautiful beaches are perfect for diving, and its lush flora and dense forests ideal for hikes. Since the rich landscape is chock full of indigenous herbs, it's also known for its traditional medicine, and home of the International Academy of Homeopathy.
        </p>
    </article>
    <hr />
</div>

);
}

export default Alonissos;

/*  File name: header.tsx
    Author name: Marcello Nunes Bernardes
    Website name: https://comp2112-final-marcellobernardes.onrender.com/
    File Description: Header page, containing the Header component
*/

import React from 'react';
import { NavLink } from 'react-router-dom';


function Header ()
{

    return(
        // Navigation Bar
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
        <div className="container-fluid">
            <NavLink to={"/home"} className="navbar-brand">
                <i className="fa-solid fa-earth-europe fa-lg"></i>
            </NavLink>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mg-lg-0">
                    <li className="nav-item">
                        <NavLink to={"/about"} className="nav-link">
                        <i className="fa-regular fa-address-card"></i> About the Author</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={"/contact"} className="nav-link">
                            <i className="fa fa-envelope fa-lg"></i> Contact</NavLink>
                    </li>
                </ul>

            </div>
        </div>
    </nav>
    );
}

export default Header;

/*  File name: home.tsx
    Author name: Marcello Nunes Bernardes
    Website name: https://comp2112-final-marcellobernardes.onrender.com/
    File Description: Home page, containing all the content from the other components
*/

import React, {useEffect} from 'react';
import Alonissos from './alonissos';
import Amorgos from './amorgos';
import Folegandros from './folegandros';
import Gavdos from './gavdos';
import Hydra from './hydra';
import Intro from './intro';
import Ithaca from './ithaca';
import Milos from './milos';
import Spetses from './spetses';
import Syros from './syros';
function Home()
{
    useEffect(() =>{
        document.title = "Home";
    },[]);

    return(
        // Creating Home page content by importing all other components in order
        <div className="container">
            <Intro></Intro>
            <Alonissos></Alonissos>
            <Amorgos></Amorgos>
            <Folegandros></Folegandros>
            <Gavdos></Gavdos>
            <Hydra></Hydra>
            <Ithaca></Ithaca>
            <Milos></Milos>
            <Spetses></Spetses>
            <Syros></Syros>
        </div>
        
    );
}

export default Home;
/*  File name: footer.tsx
    Author name: Marcello Nunes Bernardes
    Website name: https://comp2112-final-marcellobernardes.onrender.com/
    File Description: Footer page, containing the footer component
*/

import React from 'react';


function Footer()
{
    return(
        // Footer component
    <div className="container">
        <footer>
            <nav className="navbar fixed-bottom navbar-light bg-light">
                <h4>&copy; Copyright 2022 - The Travel Report</h4>
            </nav>
        </footer>
    </div>
    );
}

export default Footer;